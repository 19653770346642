export default function NavBar() {
    return (
        <div className="navbar bg-slate-200/90 dark:bg-black/80 sticky top-0 z-50 flex px-16 justify-between lg:justify-evenly">
            <a href="#aboutme" className="font-bold text-black dark:text-white normal-case text-xl">Jesus &nbsp;<span className="text-purple-500">Barrientos</span></a>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1 gap-3">
                    <li><a href="#aboutme" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">About</a></li>
                    <li><a href="#projects" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">Projects</a></li>
                    <li><a href="#technologies" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">Technologies</a></li>
                    <li><a href="https://drive.google.com/file/d/1c3L1xVs77zbxPgdKeG1r1HS4PNeA23Va/view?usp=drive_link" target="_blank" rel="noreferrer" className="text-purple-500 hover:text-purple-500 hover:drop-shadow-lg hover:border-white font-bold">Resume</a></li>
                </ul>
            </div>
            <div className="hidden lg:flex">
                <a
                    href="https://github.com/barrientosjesus"
                    target="_blank"
                    rel="noreferrer"
                    className="text-black hover:text-purple-500 dark:text-purple-500 dark:hover:text-white"
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.75em"
                        width="1.75em"
                    >
                        <path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0138.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z" />
                    </svg>
                </a>&nbsp;&nbsp;&nbsp;
                <a
                    href="https://www.linkedin.com/in/barrientosjesus/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-black hover:text-purple-500 dark:text-purple-500 dark:hover:text-white"
                >
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="currentColor"
                        height="1.75em"
                        width="1.75em"
                    >
                        <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1168.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
                    </svg>
                </a>&nbsp;&nbsp;&nbsp;
                <a
                    href="mailto:jabarri1@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-black hover:text-purple-500 dark:text-purple-500 dark:hover:text-white"
                >
                    <svg
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="1.75em"
                        width="1.75em"
                    >
                        <path d="M18.73 5.41l-1.28 1L12 10.46 6.55 6.37l-1.28-1A2 2 0 002 7.05v11.59A1.36 1.36 0 003.36 20h3.19v-7.72L12 16.37l5.45-4.09V20h3.19A1.36 1.36 0 0022 18.64V7.05a2 2 0 00-3.27-1.64z" />
                    </svg>
                </a>&nbsp;&nbsp;&nbsp;
            </div>
            <div className="flex px-6 lg:hidden lg:p-0">
                <div className="dropdown">
                    <label tabIndex={0} className="btn bg-purple-500 text-white border-purple-500 hover:text-purple-500 lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-black rounded-box hover:text-purple-500">
                        <li><a href="#aboutme" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">About</a></li>
                        <li><a href="#projects" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">Projects</a></li>
                        <li><a href="#technologies" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">Technologies</a></li>
                        <li><a href="#contact" className="text-purple-500 hover:text-white hover:bg-purple-500 dark:text-white dark:hover:text-purple-500 dark:hover:bg-white">Contact</a></li>
                        <li><a href="https://drive.google.com/file/d/1c3L1xVs77zbxPgdKeG1r1HS4PNeA23Va/view?usp=drive_link" target="_blank" rel="noreferrer" className="text-purple-500 hover:text-purple-500 hover:drop-shadow-lg hover:border-white font-bold">Resume</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}