export default function CheckTech({ techName, em }) {
    const TECHMAP = {
        "JavaScript": "https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/7c0ac2ceb9f9d24992ec393d11fa7337d2f92466/programming%20languages/javascript.svg",
        "HTML": "https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/1ac69ce5fbc389725f16f989fa53c62d6e1b4883/social%20icons/html5.svg",
        "CSS": "https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/main/others/css.svg",
        "MongoDB": "https://user-images.githubusercontent.com/25181517/182884177-d48a8579-2cd0-447a-b9a6-ffc7cb02560e.png",
        "Express": "https://user-images.githubusercontent.com/25181517/183859966-a3462d8d-1bc7-4880-b353-e2cbed900ed6.png",
        "Nodejs": "https://user-images.githubusercontent.com/25181517/183568594-85e280a7-0d7e-4d1a-9028-c8c2209e073c.png",
        "Python": "https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/7c0ac2ceb9f9d24992ec393d11fa7337d2f92466/programming%20languages/python.svg",
        "Django": "https://github.com/marwin1991/profile-technology-icons/assets/62091613/9bf5650b-e534-4eae-8a26-8379d076f3b4",
        "React": "https://raw.githubusercontent.com/bablubambal/All_logo_and_pictures/main/frameworks/react.svg",
    };

    const borderless = ["React", "Nodejs"]

    return (
        <div className={`bg-purple-500/40 rounded-lg select-none drop-shadow-sm -skew-x-3 ${borderless.includes(techName) ? 'custom-tt-2' : 'custom-tt'}`}>
            <div className="lg:tooltip tooltip-primary" data-tip={techName}>
                <img height={em} width={em} src={TECHMAP[techName]} alt={techName} />
            </div>
        </div>
    );
}